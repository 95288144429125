<template>
  <div class="main_container">
    <h2 class="page_title text-center">AJOUT RUBRIQUE</h2>
    <div class="inner_container">
      <div class="row action_bar2">
        <div class="col-auto">
          <a
            href="#"
            class="btn btn-yello mb-3"
            data-bs-toggle="modal"
            data-bs-target="#md_7"
          >
            <img src="../../assets/img/pencil_white.svg"> Modifier
          </a>
        </div>
        <div class="col-auto">
          <a class="btn btn-red mb-3">
            <img src="../../assets/img/cross_white.svg"> Supprimer
          </a>
        </div>
      </div>
    </div>
    <div class="info_tab container">
      <div class="row">
        <div class="col-md-4">
          <div class="grp_info">
            <h4>Numéro décompte:  <label>Libellé</label></h4>
          </div>
        </div>
        <div class="col-md-4 offset-md-4">
          <div class="grp_info">
            <h4>Date Debut: <label>29/9/2021</label></h4>
            <br>
            <h4>Date Fin:  <label>29/9/2021</label></h4>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-12">
          <div class="grp_info">
            <h4 class="text-center">Description</h4>
            <p>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
              rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
              consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam
              aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit
              laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea
              voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla
              pariatur?
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  @import './rubriques.css';
</style>
<script>
export default {
  name: "Rubriques"
}
</script>
